
import React from 'react'
import styled from 'styled-components'

import { Spinner } from 'components/icons/spinner'
import { localStorage } from 'components/polyfills/local-storage'
import { Button } from 'components/form/button'
import { BuilderSection } from 'components/editor/section'

const StyledForm = styled.form`
    margin: 0 10% 10%;
    padding-top: 5%;
`

export default class Builder extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            generator: {}
        }

        this.rootBuilderSection = React.createRef()

        let [, localGeneratorName] = this.props.location.pathname.split('/local/')
        this.cachedGenerator = JSON.parse(localStorage.getItem(`${process.env.GENERATOR_PREFIX_LOCAL}${localGeneratorName}`))
    }

    render() {

        return <StyledForm onSubmit={this.onSubmit}>

            <Button type='button' onClick={this.saveLocal}>
                {this.state.saveState === 'pending' && <Spinner />}
                {this.state.saveState !== 'pending' && 'Save Draft'}
            </Button>
            <Button type='submit'>
                {this.state.publishState === 'pending' && <Spinner />}
                {this.state.publishState !== 'pending' && 'Request Publication'}
            </Button>

            <BuilderSection {...this.cachedGenerator} expanded={true} root={true} ref={this.rootBuilderSection} />

        </StyledForm>

    }

    buildJSON = () => {
        return this.rootBuilderSection.current.buildJSON()
    }

    saveLocal = (event) => {
        //console.log(this.rootBuilderSection.current.title.current.value)
        //console.log(JSON.stringify(this.buildJSON()))
        localStorage.setItem(`generator---${this.rootBuilderSection.current.title.current.value}`, JSON.stringify(this.buildJSON()))
        this.setState({ saveState: 'pending' }, () => {
            setTimeout(() => {
                this.setState({ saveState: 'ready' })
            }, 1000)
        })
    }

    onSubmit = (event) => {
        event.preventDefault()
        this.setState({ publishState: 'pending' })
        fetch('https://generators-app.herokuapp.com/v2/entry/heart-of-code/generators/master/json/', {
            method: 'post',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ fields: this.buildJSON() })
        }).then(res => res.json())
        .then(data => {
            this.setState({ publishState: 'ready' })
        })
    }

}