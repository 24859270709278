
import React from 'react'
import Color from 'color'
import uniqueId from 'lodash/uniqueId'

import { Button } from 'components/form/button'
import { Input, TextArea } from 'components/form/input'
import { TrashIcon } from 'components/icons/trash'
import {
    Accordion,
    AccordionItem,
    AccordionItemTitle,
    AccordionItemTitleIcons,
    AccordionItemBody,
    AccordionArrow
} from 'components/accordion'

import 'react-accessible-accordion/dist/fancy-example.css';

export class BuilderSection extends React.Component {

    /* ------------------------------------
        Lifecycle Functions
    ------------------------------------ */

    static defaultProps = {
        depth: 0,
        deleteThis: () => {}
    }

    constructor(props) {
        super(props)
        this.state = {
            options: {},
            sections: {}
        }

        if (props.options) {
            props.options.forEach(option => {
                this.createNewSection({ map: this.state.options, props: option })
            });
        }

        if (props.sections) {
            props.sections.forEach(section => {
                this.createNewSection({ map: this.state.sections, props: section })
            })
        }

        this.title = React.createRef()
        this.description = React.createRef()

        let color = Color({ r: 230, g: 150, b: 150 })
        color = color.rotate(40 * this.props.depth)

        this.state.color = {
            base: color.hex(),
            hover: color.lighten(0.1).hex()
        }
    }

    render() {

        let { title, description, expanded } = this.props
        let isRootNode = (this.props.depth === 0)

        return <Accordion>
            <AccordionItem expanded={expanded}>

                <AccordionItemTitle bg={this.state.color.base} bghover={this.state.color.hover}>
                    <Input type='text' ref={this.title}
                        defaultValue={title}
                        label='title' placeholder='Title'
                        onClick={this.preventBubble}
                        onKeyPress={this.preventBubble}
                        injectStyle={`width: 250px; margin-left: 10px;`}
                    />
                    <TextArea ref={this.description}
                        defaultValue={description}
                        label='description' placeholder='Description'
                        onClick={this.preventBubble}
                        onKeyPress={this.preventBubble}
                        rows={1}
                        injectStyle={`width: 100%;`}
                    />
                    <AccordionItemTitleIcons>
                        {!isRootNode && <TrashIcon onClick={this.props.deleteThis} />}
                        <AccordionArrow />
                    </AccordionItemTitleIcons>
                </AccordionItemTitle>

                <AccordionItemBody>

                    <Accordion>
                        <AccordionItem>

                            <AccordionItemTitle bg={this.state.color.base} bghover={this.state.color.hover}>
                                <p>Options</p>
                                <AccordionItemTitleIcons>
                                    <AccordionArrow />
                                </AccordionItemTitleIcons>
                            </AccordionItemTitle>

                            <AccordionItemBody>
                                {Object.values(this.state.options).map(option => option.node)}
                                <Button type='button' onClick={this.addOption}>Add Option</Button>
                            </AccordionItemBody>

                        </AccordionItem>
                    </Accordion>

                    <Accordion>
                        <AccordionItem>

                            <AccordionItemTitle bg={this.state.color.base} bghover={this.state.color.hover}>
                                <p>Sections</p>
                                <AccordionItemTitleIcons>
                                    <AccordionArrow />
                                </AccordionItemTitleIcons>
                            </AccordionItemTitle>

                            <AccordionItemBody>
                                {Object.values(this.state.sections).map(section => section.node)}
                                <Button type='button' onClick={this.addSection}>Add Section</Button>
                            </AccordionItemBody>

                        </AccordionItem>
                    </Accordion>

                </AccordionItemBody>

            </AccordionItem>
        </Accordion>

    }

    /* ------------------------------------
        Class Functions
    ------------------------------------ */

    buildJSON = () => {
        return {
            title: this.title.current.value,
            description: this.description.current.value,
            options: Object.values(this.state.options).map(option => {
                return option.ref.current.buildJSON()
            }),
            sections: Object.values(this.state.sections).map(section => {
                return section.ref.current.buildJSON()
            })
        }
    }

    preventBubble = (e) => {
        e.stopPropagation()
    }

    createNewSection = ({ map, props = {} }) => {
        let id = uniqueId()

        let ref = React.createRef()
        let node = <BuilderSection key={id} ref={ref} {...props} depth={this.props.depth + 1} deleteThis={this.removeOption(id)} setParentState={this.setState} />

        map[id] = { ref, node }
    }

    addOption = ({ props = {} }) => {
        let options = this.state.options
        
        this.createNewSection({ map: options, props })
        this.setState({ options })
    }

    removeOption = (id) => (e) => {
        let options = this.state.options
        delete options[id]

        this.setState({ options })
    }

    addSection = ({ props = {} }) => {
        let sections = this.state.sections
        
        this.createNewSection({ map: sections, props })
        this.setState({ sections })
    }

    removeSection = (id) => (e) => {
        let sections = this.state.sections
        delete sections[id]

        this.setState({ sections })
    }

}