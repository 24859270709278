
import styled, { keyframes } from 'styled-components'

export const Spin = keyframes`
    to { transform: rotate(360deg); }
`

export const Spinner = styled.div`
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    border-bottom: 1px solid;
    vertical-align: middle;
    overflow: hidden;
    text-indent: 100%;

    animation: 1s ${Spin} linear infinite;

    &, &:after {
        border-radius: 100%;
    }

    &:after {
        content: "";
        position: absolute;
        top:    0;
        right:  0;
        bottom: 0;
        left:   0;
        border: 1px solid; /* [1] */
        opacity: 0.5; /* [2] */
    }

`